<script>
const urlList= []
const baseUrl= "http://jdc.hiders.cn"
const cid= ""
const nodeName=""
const loginStatus= false
const cookieStatus= false


function loginSuccess(ccid){
    this.cid =ccid
    this.cookieStatus= true
    this.loginStatus= true
    
    //设置本地储存
    localStorage.setItem("cid", ccid)
    localStorage.setItem("lastDate", GetDateStr(29))
    localStorage.setItem("baseUrl", this.baseUrl)
    localStorage.setItem("nodeName", this.nodeName)
}


//获取几天后日期
function GetDateStr(AddDayCount) {
  var dd = new Date();
  dd.setDate(dd.getDate() + AddDayCount);//获取AddDayCount天后的日期
  var y = dd.getFullYear();
  var m = (dd.getMonth() + 1) < 10 ? "0" + (dd.getMonth() + 1) : (dd.getMonth() + 1);//获取当前月份的日期，不足10补0
  var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();//获取当前几号，不足10补0
  console.log(y + "-" + m + "-" + d)
  return y + "-" + m + "-" + d;
}

export default {
    urlList,
    baseUrl,
    cid,
    loginStatus,
    cookieStatus,
    loginSuccess,
    nodeName
}
</script>