<template>
  <div class="qrcode">

    <div class="mdui-card">
<!--      <div class="mdui-card-primary">-->
<!--        <div class="mdui-card-primary-title">京东APP扫码登录</div>-->
<!--        <div class="mdui-card-primary-subtitle">{{ tip }}</div>-->
<!--        <div class="mdui-card-media">-->
<!--          <img :src="imageBase">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="mdui-card-actions">-->
<!--        <div style="padding-bottom: 10px">-->
<!--          <button v-if="isOk" @click="getQrCode" class="mdui-btn mdui-btn-block mdui-ripple mdui-color-indigo">生成二维码</button>-->
<!--        </div>-->
<!--      </div>-->

      <div class="mdui-card-primary">
        <div class="mdui-card-primary-title">首次挂机需手动操作内容：</div>
        <div class="mdui-card-primary-subtitle">
          <ol>
            <li>京东APP-首页-0元免费水果：选取要种植的水果。</li>
            <li>京东APP-我的-更多工具-休闲游戏：进入各游戏完成新手引导。</li>
            <li>京喜APP-我的-京喜工厂：选择要生产的产品，完成新手引导。</li>
            <li>京喜APP-我的-京喜牧场：完成新手引导。</li>
            <li>微信APP-我-支付-拖到页面下方，点击购物消费分类中的「京东购物」，使用微信登录京东这个小程序，如果已登录那么退出重新使用微信登录。</li>
            <li>新注册帐号建议手动随意浏览商品，简单玩游戏1-2天后再登录辅助（不易黑号）。</li>
          </ol>
        </div>
      </div>

      <div class="mdui-card-primary">
        <div class="mdui-card-primary-title">辅助登录(任选一种)</div>
        <ul>
          <li class="mdui-card-primary">
            <a href="http://x.cdn.hiders.cn/jdc.apk">Android用户点击此处下载APP登录(微信中无法下载的可在浏览器中打开后点击)</a>
          </li>
          <!--          <li class="mdui-card-primary"><a href="http://nolan.hiders.cn" target="_blank">网页或IOS用户登录(体验版)</a></li>-->
          <li class="mdui-card-primary">
            <textarea v-model="cookie" class="mdui-textfield-input" rows="4"
                      placeholder="抓包Cookie后点击此处填写提交。"></textarea>
            <div class="mdui-card-actions">
              <div style="padding-bottom: 10px">
                <button v-if="isOk" @click="submit" class="mdui-btn mdui-btn-block mdui-ripple mdui-color-indigo">提交
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="mdui-card-primary">
        <div class="mdui-card-primary-title">常用路径:</div>
        <div class="mdui-card-primary-subtitle">
          <ol>
            <li>【极速金币】京东极速版->我的->金币(极速版使用)</li>
            <li>【京东赚赚】微信->京东赚赚小程序->底部赚好礼->提现无门槛红包(京东使用)</li>
            <li>【京东秒杀】京东->中间频道往右划找到京东秒杀->中间点立即签到->兑换无门槛红包(京东使用)</li>
            <li>【东东萌宠】京东->我的->东东萌宠,完成是京东红包,可以用于京东app的任意商品</li>
            <li>【领现金】京东->我的->东东萌宠->领现金(微信提现+京东红包)</li>
            <li>【东东农场】京东->我的->东东农场,完成是京东红包,可以用于京东app的任意商品</li>
            <li>【京喜工厂】京喜->我的->京喜工厂,完成是商品红包,用于购买指定商品(不兑换会过期)</li>
            <li>【京东试用】京东->我的->新品试用->我的试用->申请成功(免费领取)</li>
            <li>【其他】京喜红包只能在京喜使用,其他同理</li>
          </ol>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import global from '../components/Common'
import axios from 'axios'
import mdui from 'mdui'

export default {
  name: "QrCode",
  data: function () {
    return {
      imageBase: "",
      timer: "",//定时器
      msg: "",//弹出提示
      okl_token: "",
      cookie: "",
      token: "",
    }
  },
  props: {
    tip: String,
    isOk: Boolean
  },
  methods: {
    //获取二维码
    getQrCode() {
      let self = this
      //获取二维码请求
      axios.get("http://jdc.hiders.cn/api/cookie/qrcode").then(function (res) {
        if (res.data.errcode == 0) {
          localStorage.setItem("qrcode_expire", Date.now() + 2 * 60 * 1000)
          localStorage.setItem("qrcode", JSON.stringify(res.data))
          self.imageBase = 'data:image/jpeg;base64,' + res.data.qrCode
          self.cookie = res.data.cookie
          self.token = res.data.token
          self.okl_token = res.data.okl_token
          //清除定时器
          clearInterval(self.timer)
          //设置定时器
          self.timer = setInterval(self.checkReq, 2000);
        } else {
          mdui.snackbar(res.data.message)
        }
      }).catch(function () {
        mdui.snackbar("该服务器已离线，请选择其他服务器！")
      })
    },
    //检测登录请求
    checkReq() {
      let self = this
      axios.post("http://jdc.hiders.cn/api/cookie/check", {
        cookie: this.cookie,
        token: this.token,
        okl_token: this.okl_token
      }).then(function (res) {
        if (res.data.errcode == 0) {
          clearInterval(self.timer)
          //本地存储cid
          global.loginSuccess(res.data.account)
          //跳转页面
          self.$router.push({path: "/home"})
          //清空二维码
          self.imageBase = ""
        } else if (res.data.errcode != 176) {
          clearInterval(self.timer)
          mdui.snackbar(res.data.message)
          self.imageBase = ""
        }
      })
    },
    //提交Cookie
    submit() {
      let self = this
      axios.post("http://jdc.hiders.cn/api/cookie/add", {
        cookie: this.cookie,
      }).then(function (res) {
        if (res.data.code >= 1) {
          //本地存储cid
          global.loginSuccess(res.data.data.account)
          localStorage.setItem("lastDate", res.data.data.cookie_expire_date)
          //跳转页面
          self.$router.push({path: "/home"})
        } else {
          mdui.snackbar(res.data.msg)
        }
      })
    }
  },
  mounted: function () {
    let self = this
    if (Date.now() < localStorage.getItem("qrcode_expire")) {
      console.log(321)
      let res = JSON.parse(localStorage.getItem("qrcode"))
      console.log(res)
      self.imageBase = 'data:image/jpeg;base64,' + res.qrCode
      self.cookie = res.cookie
      self.token = res.token
      self.okl_token = res.okl_token
      self.timer = setInterval(self.checkReq, 2000);
    }
  },
}
</script>

<style scoped>
.qrcode {
  margin-top: 20px
}
</style>