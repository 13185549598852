<template>
  <div class="my">
    <div class="mdui-card">
      <div class="mdui-card-primary">
        <div class="mdui-card-primary-title">微信扫码获取领奖及过期通知</div>
      </div>
      <div class="mdui-card-media">
        <img class="image" :src="image"/>
      </div>
      <div class="mdui-card-primary">
        <div class="mdui-card-primary-title">个人信息</div>
      </div>
      <div class="mdui-card-content">
        <div class="mdui-typo-body-2 item">帐号：
          <div class="mdui-chip">
            <span class="mdui-chip-icon mdui-color-blue"><i class="mdui-icon material-icons">face</i></span>
            <span class="mdui-chip-title">{{ cid }}</span>
          </div>
        </div>
        <div class="mdui-typo-body-2 item">状态：
          <div class="mdui-chip">
            <span :class="nowStatusClass"><i class="mdui-icon material-icons">check_circle</i></span>
            <span class="mdui-chip-title">{{ nowStatusStr }}</span>
          </div>
        </div>
        <div class="mdui-typo-body-2 item">过期时间：
          <div class="mdui-chip">
            <span class="mdui-chip-icon mdui-color-red"><i class="mdui-icon material-icons">report</i></span>
            <span class="mdui-chip-title">{{ lastDate }}</span>
          </div>
        </div>

        <div class="mdui-typo-body-2 item">请于过期时间前再次扫码登录。</div>
      </div>
      <div class="mdui-card-actions">
        <button @click="logout" class="mdui-btn mdui-ripple mdui-color-red">切换帐号</button>
        <!--                <button @click="del" class="mdui-btn mdui-ripple mdui-color-red">删除账号</button>-->
      </div>
    </div>

    <div class="mdui-dialog" id="delete">
      <div class="mdui-dialog-title">删除</div>
      <div class="mdui-dialog-content">确认从系统中移除你的cookie吗？</div>
      <div class="mdui-dialog-actions">
        <button @click="checkDel" class="mdui-btn mdui-ripple" mdui-dialog-confirm>确认</button>
        <button class="mdui-btn mdui-ripple" mdui-dialog-confirm>取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import mdui from 'mdui'

export default {
  name: "My",
  data: function () {
    return {
      baseUrl: "",
      nick: "",
      status: 1,
      nodeName: "",
      timestamp: "",
      cid: "",
      lastDate: "",
      image: "card.webp"
    }
  },
  methods: {
    getInfo() {
      this.baseUrl = localStorage.getItem("baseUrl")
      // var self= this
      this.cid = localStorage.getItem("cid")
      this.lastDate = localStorage.getItem("lastDate")
      this.getNotifyQr()
      //获取账号信息
      // axios.post(this.baseUrl+"/checkcookie",{cid:localStorage.getItem("cid")}).then(function(res){
      //     self.nick= res.data.data.nickname
      //     self.status= res.data.data.status
      //     self.timestamp=res.data.data.timestamp
      //     self.nodeName=localStorage.getItem("nodeName")
      // }).catch(function(res){
      //     mdui.snackbar("服务器连接失败！")
      //     console.log(res)
      // })
    },
    logout() {
      localStorage.removeItem("cid")
      localStorage.removeItem("lastDate")
      this.$router.replace("/")
    },
    del() {
      var instD = new mdui.Dialog('#delete');
      instD.open()
    },
    checkDel() {
      var self = this
      axios.post(this.baseUrl + "/delete", {
        cid: this.cid,
      }).then(function (res) {
        mdui.snackbar(res.data.data)
        self.logout()
      })
    },
    getNotifyQr() {
      let self = this
      axios.post("http://jdc.hiders.cn/api/cookie/notifyQrcode", {
        account: this.cid
      }).then(function (res) {
        console.log(res)
        if (res.data.errcode === 0) {
          self.image = res.data.qrcode
          alert("微信扫码或识别二维码获取每日收益/红包过期通知。")
        }
      })
    }
  },
  mounted: function () {
    this.getInfo()
  },
  computed: {
    nowStatusStr() {
      if (this.status == 1) {
        return "正常"
      } else {
        return "已失效"
      }
    },
    nowStatusClass() {
      if (this.status == 1) {
        return ["mdui-chip-icon", "mdui-color-blue"]
      } else {
        return ["mdui-chip-icon", "mdui-color-red"]
      }
    }
  }
}
</script>

<style scoped>
.my {
  margin-top: 20px;
}

.item {
  margin-top: 10px;
}
</style>